import {
  DEFAULT_CINEMA_LANGUAGE,
  DEFAULT_CINEMA_HANDLER,
  CINEMA_LANGUAGES,
  isValidCinemaLang,
  isValidCinemaSlug,
  DEFAULT_ONLY_LANGUAGE,
} from "~/utils/constants";
import { navigateTo } from "#imports";

export default defineNuxtRouteMiddleware((to) => {
  const WHITE_LIST = ["/undefined", "_nuxt", "style-guide", "login"];
  if (WHITE_LIST.some((item) => to.path?.includes(item))) {
    return;
  }

  const lastVisitedLang = useCookie("lastVisitedLang");
  const lastVisitedLocation = useCookie("lastVisitedLocation");

  const hasInvalidLanguage =
    !isValidCinemaLang(to.params.lang) ||
    !Object.values(CINEMA_LANGUAGES).some(
      (lang) => to.path?.startsWith?.(`/${lang}`),
    ) ||
    (DEFAULT_ONLY_LANGUAGE
      ? to.params.lang !== DEFAULT_CINEMA_LANGUAGE
      : false);

  const hasInvalidCinemaSlug =
    !to.params?.cinemaslug || !isValidCinemaSlug(to.params.cinemaslug);

  if (
    (!isValidCinemaLang(to.params.lang) || !to.params?.cinemaslug) &&
    !Object.values(CINEMA_LANGUAGES).some(
      (lang) => to.path?.startsWith?.(`/${lang}`),
    ) &&
    to.path?.length < 1000
  ) {
    if (lastVisitedLocation.value) {
      return navigateTo(`${lastVisitedLocation.value}${to.fullPath}`);
    } else if (to.path !== "/") {
      return navigateTo(
        `/${
          lastVisitedLang.value ?? DEFAULT_CINEMA_LANGUAGE
        }/${DEFAULT_CINEMA_HANDLER}${to.fullPath}`,
      );
    }
  } else if (
    (hasInvalidLanguage || hasInvalidCinemaSlug) &&
    to.path?.length < 1000
  ) {
    if (lastVisitedLocation.value) {
      return navigateTo(lastVisitedLocation.value);
    } else if (to.path !== "/") {
      return navigateTo(
        `/${
          lastVisitedLang.value ?? DEFAULT_CINEMA_LANGUAGE
        }/${DEFAULT_CINEMA_HANDLER}`,
      );
    }
  }
});
