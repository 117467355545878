import validate from "/srv/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/srv/app/middleware/auth.global.js";
import live_45preview_45token_45global from "/srv/app/middleware/livePreviewToken.global.ts";
import redirect_45to_45valid_45cinema_45global from "/srv/app/middleware/redirectToValidCinema.global.ts";
import removed_45trailing_45slashes_45global from "/srv/app/middleware/removedTrailingSlashes.global.ts";
import manifest_45route_45rule from "/srv/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  live_45preview_45token_45global,
  redirect_45to_45valid_45cinema_45global,
  removed_45trailing_45slashes_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}