import { isAfter, isBefore, isSameHour, isToday, isTomorrow } from "date-fns";
import { CINEMA_LANGUAGES, DEFAULT_CINEMA_LANGUAGE } from "./constants";

export function isObjectEmpty(objectName) {
  return (
    objectName &&
    Object.keys(objectName).length === 0 &&
    objectName.constructor === Object
  );
}

export function deepClone(obj) {
  if (Array.isArray(obj)) {
    return obj.map(deepClone);
  }
  if (obj && typeof obj === "object") {
    const cloned = {};
    const keys = Object.keys(obj);
    for (let i = 0, l = keys.length; i < l; i += 1) {
      const key = keys[i];
      cloned[key] = deepClone(obj[key]);
    }
    return cloned;
  }
  return obj;
}

const accentMap = {
  ae: "(ae|æ|ǽ|ǣ)",
  a: "(a|á|ă|ắ|ặ|ằ|ẳ|ẵ|ǎ|â|ấ|ậ|ầ|ẩ|ẫ|ä|ǟ|ȧ|ǡ|ạ|ȁ|à|ả|ȃ|ā|ą|ᶏ|ẚ|å|ǻ|ḁ|ⱥ|ã)",
  c: "(c|ć|č|ç|ḉ|ĉ|ɕ|ċ|ƈ|ȼ)",
  d: "(ď|Ď|ḑ|Ḑ|ḍ|Ḍ|ḏ|Ḏ|đ|Đ|ᵭ|ɖ|ɗ|ƌ|ȡ)",
  e: "(e|é|ĕ|ě|ȩ|ḝ|ê|ế|ệ|ề|ể|ễ|ḙ|ë|ė|ẹ|ȅ|è|ẻ|ȇ|ē|ḗ|ḕ|ⱸ|ę|ᶒ|ɇ|ẽ|ḛ)",
  i: "(i|í|ĭ|ǐ|î|ï|ḯ|ị|ȉ|ì|ỉ|ȋ|ī|į|ᶖ|ɨ|ĩ|ḭ)",
  l: "(l|ŀ|ĺ|ľ|ļ|ḽ|ȴ|ḷ|ḹ|ⱡ|ɫ|ɬ|ɭ|ł)",
  n: "(n|ń|ň|ņ|ṋ|ȵ|ṅ|ṇ|ǹ|ɲ|ṉ|ƞ|ᵰ|ᶇ|ɳ|ñ)",
  o: "(o|ó|ŏ|ǒ|ô|ố|ộ|ồ|ổ|ỗ|ö|ȫ|ȯ|ȱ|ọ|ő|ȍ|ò|ỏ|ơ|ớ|ợ|ờ|ở|ỡ|ȏ|ō|ṓ|ṑ|ǫ|ǭ|ø|ǿ|õ|ṍ|ṏ|ȭ)",
  r: "(r|ŕ|ř|ȑ|ȓ|ṙ|ṛ|ṝ|ɍ|ɽ|ṟ|ɼ|ɾ|ᵳ|ȑ|ɹ|ɺ|ⱹ)",
  s: "(š|Š|ś|Ś|ṥ|ŝ|ș|ṡ|š|ṧ|ṣ|ṩ|ș)",
  t: "(ť|Ť|ṫ|ț|ṭ|ţ|ṱ|ț|ṯ|ŧ|ᵵ|ƫ|ƭ)",
  u: "(u|ú|ů|Ů|ŭ|ǔ|û|ṷ|ü|ǘ|ǚ|ǜ|ǖ|ṳ|ụ|ű|ȕ|ù|ủ|ư|ứ|ự|ừ|ử|ữ|ȗ|ū|ṻ|ų|ᶙ|ů|ũ|ṹ|ṵ)",
  y: "(y|ý|ŷ|ÿ|ỵ|ỳ|ƴ|ỷ|ȳ|ẙ|ɏ|ỹ)",
  z: "(ž|Ž|ź|Ź|ẑ|ż|ž|ẓ|ẕ)",
};

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

export function highlightQueries(text, queries, highlightClass = "highlight") {
  const accentRegex = new RegExp(Object.keys(accentMap).join("|"), "g");
  const queryRegex = new RegExp(
    queries
      .map((q) => {
        return escapeRegExp(q)
          .toLowerCase()
          .replace(accentRegex, (m) => {
            return accentMap[m] || m;
          });
      })
      .join("|"),
    "gi",
  );
  return JSON.stringify(text)
    .slice(1, -1)
    .replace(queryRegex, (m) => `<span class="${highlightClass}">${m}</span>`);
}

export const isDescendant = function (parent, child) {
  let node = child.parentNode;
  while (node) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const transformEnumsToKeyedMap = (enumOutputArray = [], prefix = "") => {
  const translations = {};
  enumOutputArray.forEach((enumOutput) => {
    if (enumOutput.cases) {
      const cases = {};
      enumOutput.cases.forEach((enumCase) => {
        cases[enumCase.value] = enumCase.title;
      });
      translations[`${prefix ? prefix + "." : ""}${enumOutput.name}`] = cases;
    }
  });
  return translations;
};

export const getObjectValueByPath = (obj, path) => {
  let searchedObject = deepClone(obj);
  const pathArray = path.split(".");
  for (const key of pathArray) {
    if (!searchedObject[key]) {
      return null;
    }
    searchedObject = searchedObject[key];
  }
  return searchedObject;
};

export const createNestedObject = (obj) => {
  return Object.entries(obj).reduce((res, [key, value]) => {
    const propertyPathParts = key.split(".");
    const lastPart = propertyPathParts.pop();
    let currentLevel = res;
    propertyPathParts.forEach((part) => {
      if (!currentLevel[part]) {
        currentLevel[part] = {};
      }
      currentLevel = currentLevel[part];
    });
    currentLevel[lastPart] = deepClone(value);
    return res;
  }, {});
};

export const flattenObject = (obj, prefix = "") => {
  return Object.keys(obj).reduce((res, key) => {
    const pre = prefix.length ? prefix + "." : "";
    if (
      !Array.isArray(obj[key]) &&
      obj[key] !== null &&
      typeof obj[key] === "object"
    ) {
      Object.assign(res, flattenObject(obj[key], pre + key));
    } else {
      res[pre + key] = obj[key];
    }
    return res;
  }, {});
};

export function addNbsp(string) {
  /* eslint-disable no-irregular-whitespace */
  let firstWhiteSpaceKept = false;
  return String(string)
    .split(" ")
    .filter((word) => {
      if (!word && !firstWhiteSpaceKept) {
        firstWhiteSpaceKept = true;
        return true;
      }
      return word;
    })
    .map((word) =>
      word.length > 2 || /\n|\r|-|‒|–|—|―/.test(word) || !word.length
        ? `${word} `
        : `${word} `,
    )
    .join("")
    .slice(0, -1);
}

export function traverseDomToAddNbsp(el) {
  if (!el?.childNodes?.length && !el?.childElementCount) {
    if (String(el?.nodeValue)?.replace(/(\s|\n)/gm, "")) {
      el.nodeValue = `${addNbsp(el.nodeValue)}`;
      if (
        el?.previousSibling?.innerHTML &&
        !el.nodeValue?.startsWith?.(".") &&
        !el.nodeValue?.startsWith?.(",") &&
        !el.nodeValue?.startsWith?.(";") &&
        !(
          el?.previousSibling?.innerHTML?.endsWith?.(" ") ||
          el?.previousSibling?.innerHTML?.endsWith?.("&nbsp;") ||
          el?.previousSibling?.innerHTML?.endsWith?.(" ")
        )
      ) {
        el.nodeValue = `${
          [")", "}", "]", ",", "."].some((bracket) =>
            el.nodeValue?.startsWith?.(bracket),
          )
            ? ""
            : " "
        }${el.nodeValue}`;
      }
    } else if (String(el?.innerHTML)?.replace(/(\s|\n)/gm, "")) {
      el.innerHTML = `${el?.previousSibling?.innerHTML ? " " : ""}${addNbsp(
        el.innerHTML,
      )}`;
    }
    return;
  }
  el?.childNodes?.forEach?.((child, index) => {
    traverseDomToAddNbsp(child, index);
  });
}

export const cloneSplice = (arr, index, deleteCount) => {
  const clone = deepClone(Array.isArray(arr) ? arr : []);
  clone.splice(index, deleteCount);
  return clone;
};

export const checkIfAnyChildOverflows = (el, direction = "x") => {
  if (!el || !el?.style) {
    return false;
  }
  const originalOverflow = el?.style?.overflow ?? "none";
  el.style.overflow = "auto";
  if (direction === "x") {
    if (el?.offsetWidth < el?.scrollWidth) {
      el.style.overflow = originalOverflow;
      return true;
    }
  } else {
    if (el?.offsetHeight < el?.scrollHeight) {
      el.style.overflow = originalOverflow;
      return true;
    }
  }
  return false;
};
export const getScrollbarWidth = () => {
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll";
  outer.style.msOverflowStyle = "scrollbar";
  document.body.appendChild(outer);

  const inner = document.createElement("div");
  outer.appendChild(inner);
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};

export function objectTransformer({
  dataObj,
  nullableProps = [],
  nullableAll = false,
  preserveZeroes = false,
  includePropsIfNilOrEmptyStr = true,
  flatToNested = false,
}) {
  const postData = {};
  const isNilOrEmptyString = (value) => {
    return value === "" || value === undefined || value === null;
  };
  for (const property of Object.keys(dataObj)) {
    if ([true, false].includes(dataObj?.[property])) {
      // send boolean as boolean
      postData[property] = dataObj[property];
    } else if (typeof dataObj?.[property] !== "undefined") {
      if (
        !isNilOrEmptyString(dataObj?.[property]) ||
        includePropsIfNilOrEmptyStr
      ) {
        if (preserveZeroes && dataObj[property] === 0) {
          postData[property] = dataObj[property];
        } else {
          postData[property] =
            dataObj[property] ||
            (nullableProps.includes(property) || nullableAll ? null : "");
        }
      }
    } else if (
      dataObj[property] &&
      dataObj[property] === Object(dataObj[property])
    ) {
      postData[property] = objectTransformer({
        formObject: dataObj?.[property],
        nullableProps,
        nullableAll,
        preserveZeroes,
        includePropsIfNilOrEmptyStr,
      });
    }
  }
  if (flatToNested) {
    return createNestedObject(postData);
  }
  return postData;
}

export const CINEMA_HALL_TYPES = {
  gold: "6",
  deluxe: "33",
};

export const kebabToCamelCase = (s) => {
  if (!s) return "";
  return s.replace(/-./g, (x) => x[1].toUpperCase());
};

export const getSiteHandle = (kebabCase = false) => {
  const route = useRoute();
  return route?.params?.cinemaslug
    ? `${
        kebabCase
          ? route?.params?.cinemaslug
          : kebabToCamelCase(route?.params?.cinemaslug)
      }${route?.params.lang === "cz" ? "" : capitalize(route?.params.lang)}`
    : "default";
};

export const getGroupSiteHandles = (kebabCase = false) => {
  const route = useRoute();
  return Object.values(CINEMA_LANGUAGES).map((lang) => {
    if (lang === DEFAULT_CINEMA_LANGUAGE) {
      return kebabCase
        ? route.params.cinemaslug
        : kebabToCamelCase(route.params.cinemaslug);
    }
    return `${
      kebabCase
        ? route.params.cinemaslug
        : kebabToCamelCase(route.params.cinemaslug)
    }${capitalize(lang)}`;
  });
};

export const slugify = (str) => {
  return str
    .toString()
    .toLowerCase()
    .normalize("NFD")
    .trim()
    .replace("/", "")
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "-")
    .replace(/--+/g, "-");
};

export const screeningTimes = [
  [3, 12, "9 - 11:00"],
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  [23, 3, "23:00 +"],
];

export const getMostMovieScreeningsInRow = (movie, screeningsInDay) => {
  if (!movie?.screening?.length) {
    return 1;
  }
  const timesMap = new Map(screeningTimes?.map?.((time) => [time, 0]) ?? []);
  movie?.screening?.some?.((screening) => {
    screeningTimes.forEach((time) => {
      if (
        time?.[0] !== undefined
          ? isAfter(
              screening?.Start,
              new Date(screeningsInDay).setHours(time?.[0]) - 1,
            ) &&
            isBefore(
              screening?.Start,
              new Date(screeningsInDay).setHours(
                time?.[1] + (time?.[1] <= time?.[0] ? 24 : 0),
              ),
            )
          : isSameHour(
              screening?.Start,
              new Date(screeningsInDay).setHours(time ?? 0),
            )
      ) {
        timesMap.set(time, (timesMap.get(time) ?? 0) + 1);
      }
    });
  });
  return Math.max(...timesMap.values());
};

export const dayLabelFormatter = (
  day,
  {
    wholeDate = false,
    noWeekDay = false,
    today = null,
    tomorrow = null,
    includeTime = false,
    fullMonthFormat = false,
  } = {},
) => {
  const $route = useRoute();
  const isEnglish = $route?.params?.lang === "en";
  let date = null;
  try {
    date = new Date(day);
  } catch (e) {}
  if (!date) {
    return "&nbsp;";
  }
  if (today && isToday(date)) {
    return today ?? "";
  }
  if (tomorrow && isTomorrow(date)) {
    return (
      tomorrow +
        " " +
        `<span class="day-number">${date?.toLocaleDateString?.(
          isEnglish ? "en-GB" : "cs-CZ",
          {
            day: "numeric",
            month: fullMonthFormat ? "long" : "numeric",
          },
        )}</span>` ?? ""
    );
  }
  if (wholeDate) {
    return (
      date?.toLocaleDateString?.(isEnglish ? "en-GB" : "cs-CZ", {
        day: "numeric",
        month: fullMonthFormat ? "long" : "numeric",
        year: "numeric",
      }) +
      (includeTime
        ? " " +
          date?.toLocaleTimeString?.(isEnglish ? "en-GB" : "cs-CZ", {
            hour: "2-digit",
            minute: "2-digit",
          })
        : "")
    );
  }

  return (
    (!noWeekDay
      ? capitalize(
          date?.toLocaleDateString?.(isEnglish ? "en-GB" : "cs-CZ", {
            weekday: "short",
          }),
        ) + " "
      : "") +
    `<span class="day-number">${date?.toLocaleDateString?.(
      isEnglish ? "en-GB" : "cs-CZ",
      {
        day: "numeric",
        month: fullMonthFormat ? "long" : "numeric",
      },
    )}</span>` +
    (includeTime
      ? " " +
        date?.toLocaleTimeString?.(isEnglish ? "en-GB" : "cs-CZ", {
          hour: "2-digit",
          minute: "2-digit",
        })
      : "")
  );
};

export const nextWeekWednesday = () => {
  const date = new Date();
  date.setDate(date.getDate() - date.getDay() + 7);
  date.setDate(date.getDate() + ((4 + 7 - date.getDay()) % 7));
  date.setHours(0, 0, 0, 0);
  return date;
};

export const formatDateTimeByTimezone = (
  isoString,
  options = { timezoneOffset: 2.0 },
) => {
  const targetDateTime = new Date(isoString);
  const tzDifference =
    options.timezoneOffset * 60 + targetDateTime.getTimezoneOffset();
  return new Date(targetDateTime.getTime() + tzDifference * 60 * 1000);
};

export const getActualDateWithOffset = ({ subMinutes = 0 } = {}) => {
  const MS_PER_MINUTE = 60000;
  const currentDate = new Date();
  return new Date(currentDate - subMinutes * MS_PER_MINUTE);
};

export const blockScrollUpUpdate = (state = true) => {
  const scrollUpUpdateBlocked = useState("blockScrollUpUpdate", () => state);
  scrollUpUpdateBlocked.value = state;
};

let unBlockerTimeout = null;
export const scrollElIntoView = (el, smooth = true) => {
  clearTimeout(unBlockerTimeout);
  const targetElement =
    typeof el === "string" ? window.document.querySelector(el) : el;
  if (
    (!targetElement?.scrollIntoView && !targetElement) ||
    (targetElement && targetElement.style.display === "none")
  ) {
    return false;
  }
  blockScrollUpUpdate(true);
  (el?.scrollIntoView
    ? el
    : window.document.querySelector(el)
  )?.scrollIntoView?.({
    behavior: smooth ? "smooth" : "instant",
    alignTop: true,
  });
  unBlockerTimeout = setTimeout(() => {
    blockScrollUpUpdate(false);
  }, 750);
  return true;
};

const CINEMA_HALL_SITE_TYPE = "cinemaHall";

export const getPageTheme = (data) => {
  if (data?.cinemaHallType?.[0]?.tagColor)
    return `theme-${data.cinemaHallType[0].tagColor}`;
  if (
    data?.parent?.typeHandle === CINEMA_HALL_SITE_TYPE &&
    data?.parent?.cinemaHallType?.[0]?.tagColor
  ) {
    return `theme-${data.parent.cinemaHallType[0].tagColor}`;
  }
  return "";
};

export const getCinemaHallType = (data) => {
  if (data?.cinemaHallType?.[0]) return data.cinemaHallType[0];
  if (
    data?.parent?.typeHandle === CINEMA_HALL_SITE_TYPE &&
    data.parent.cinemaHallType?.[0]
  )
    return data.parent.cinemaHallType[0];
  return null;
};

export const getCinemaMovieTagCodes = (data) => {
  if (data?.categoryTags?.length > 0)
    return data.categoryTags.map((tag) => tag);
  if (
    data?.parent?.typeHandle === CINEMA_HALL_SITE_TYPE &&
    data.parent.categoryTags?.length > 0
  )
    return data.parent.categoryTags.map((tag) => tag);
  return [];
};

export const getCinemaHallTypeParentUri = (data) => {
  if (data?.parent?.typeHandle === CINEMA_HALL_SITE_TYPE && data?.parent?.uri) {
    return data.parent.uri;
  }
  return null;
};
