import Globals from "~/queries/Globals.gql";
import Navigations from "~/queries/Navigations.gql";
import Homepage from "~/queries/Homepage.gql";
import Page from "~/queries/Page.gql";
import PageUris from "~/queries/PageUris.gql";
import WeekMovies from "~/queries/WeekMovies.gql";
import ActionItems from "~/queries/ActionItems.gql";
import CinemaScreenings from "~/queries/CinemaScreenings.gql";
import CinemaScreeningsWithMovies from "~/queries/CinemaScreeningsWithMovies.gql";
import ListMainMovies from "~/queries/ListMainMovies.gql";
import ListSubMovies from "~/queries/ListSubMovies.gql";
import CinemaSpecificMovies from "~/queries/CinemaSpecificMovies.gql";
import AdvertisingItems from "~/queries/AdvertisingItems.gql";
import CinemaHalls from "~/queries/CinemaHalls.gql";

import {
  useGlobalsSchemaQuery,
  useNavigationsSchemaQuery,
  useHomepageSchemaQuery,
  useOtherPageSchemaQuery,
  useAsyncComponentsSchemaQuery,
  useCinemaScreeningsSchemaQuery,
  useSpecificMoviesSchemaQuery,
  useCinemaHallsSchemaQuery,
} from "~/composables/useFetchCraftClient";
import { getSiteHandle } from "~/utils/common";

const useFetchGlobalsData = (variables) => {
  return useGlobalsSchemaQuery(Globals, variables);
};

const useFetchNavigationsData = (variables) => {
  return useNavigationsSchemaQuery(Navigations, variables);
};

const useFetchHomepageData = () => {
  return useHomepageSchemaQuery(Homepage);
};

const useFetchOtherPageData = (variables) => {
  return useOtherPageSchemaQuery(Page, variables);
};

const useFetchOtherPageUrisData = (variables) => {
  return useOtherPageSchemaQuery(PageUris, variables);
};

const useFetchWeekMoviesData = () => {
  return useAsyncComponentsSchemaQuery(WeekMovies);
};

const useFetchActionItemsData = () => {
  return useAsyncComponentsSchemaQuery(ActionItems);
};

const useFetchMainMoviesData = (options) => {
  return useAsyncComponentsSchemaQuery(ListMainMovies, options);
};

const useFetchSubMoviesData = (options) => {
  return useAsyncComponentsSchemaQuery(ListSubMovies, options);
};

const useFetchCinemaScreeningsData = (variables) => {
  return useCinemaScreeningsSchemaQuery(CinemaScreenings, variables);
};

const useFetchCinemaScreeningsWithMoviesData = (variables) => {
  return useCinemaScreeningsSchemaQuery(CinemaScreeningsWithMovies, variables);
};

const useFetchSpecificMoviesData = () => {
  return useSpecificMoviesSchemaQuery(CinemaSpecificMovies, {
    variables: {
      site: getSiteHandle(),
    },
  });
};

const useFetchAdvertisingData = () => {
  return useHomepageSchemaQuery(AdvertisingItems, {
    variables: {
      sites: [getSiteHandle()],
    },
  });
};

const useFetchCinemaHallsData = (variables) => {
  return useCinemaHallsSchemaQuery(CinemaHalls, variables);
};

export {
  useFetchGlobalsData,
  useFetchNavigationsData,
  useFetchHomepageData,
  useFetchOtherPageData,
  useFetchOtherPageUrisData,
  useFetchWeekMoviesData,
  useFetchActionItemsData,
  useFetchMainMoviesData,
  useFetchSubMoviesData,
  useFetchCinemaScreeningsData,
  useFetchCinemaScreeningsWithMoviesData,
  useFetchSpecificMoviesData,
  useFetchAdvertisingData,
  useFetchCinemaHallsData,
};
